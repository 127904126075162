import React, { useState, useEffect } from 'react';
import {
  useStripe, useElements,
  CardNumberElement, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import { isAuthenticated } from "../auth";
import { stripePaymentMethodHandler } from './stripeScript';
import { sendMessage } from "../user/apiUser";
import { registerSponsor } from '../event/apiEvents'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutSponsorForm(props) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [sponsorLevel, setSponsorLevel] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setErrorMsg('');

    const paymentMethodObj = {
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email
      }
    };

    const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);
    var sponsorLevel = ''
    if (props.platinum) { sponsorLevel = 'Platinum' }
    if (props.gold) { sponsorLevel = 'Gold' }
    if (props.silver) { sponsorLevel = 'Silver'}
    if (props.bronze) { sponsorLevel = 'Bronze' }
    if (props.beverage) { sponsorLevel = 'Beverage' }
    if (props.contributing) { sponsorLevel = 'Contributing' }
    if (props.wifi) { sponsorLevel = 'wifi'}
    if (props.breakoutRoom) { sponsorLevel = 'Break Out Room' }
    if (props.breakStation) { sponsorLevel = 'Break Station' }
    if (props.meal) { sponsorLevel = 'Meal' }
    if (props.elevator) { sponsorLevel = 'Elevator' }
    if (props.technology) { sponsorLevel = 'Technology' }

    var description = `${sponsorLevel} sponsorship fee for the SDSIC Spring Event 2024`

    stripePaymentMethodHandler({
      result: paymentMethodResult,
      amount: props.amount,
      description: description,
      email: email
    }, handleResponse);

  };


  // callback method to handle the response
  const handleResponse = async response => {
    setLoading(false);
    if (response.error) {
      setErrorMsg(typeof response.error === 'string' ? response.error : response.error.message);
      return;
    }
    props.setPaymentCompleted(response.success ? true : false);
    props.setReceiptUrl(response.receipt_url)
    props.setReceiptEmail(email)
    props.setReceiptName(name)

    var sponsorLevel = ''
    if (props.platinum) { sponsorLevel = 'Platinum' }
    if (props.gold) { sponsorLevel = 'Gold' }
    if (props.silver) { sponsorLevel = 'Silver'}
    if (props.bronze) { sponsorLevel = 'Bronze' }
    if (props.beverage) { sponsorLevel = 'Beverage' }
    if (props.contributing) { sponsorLevel = 'Contributing' }
    if (props.wifi) { sponsorLevel = 'wifi'}
    if (props.breakoutRoom) { sponsorLevel = 'Break Out Room' }
    if (props.breakStation) { sponsorLevel = 'Break Station' }
    if (props.meal) { sponsorLevel = 'Meal' }
    if (props.elevator) { sponsorLevel = 'Elevator' }
    if (props.technology) { sponsorLevel = 'Technology' }

    await sendEmailReceipt(response.receipt_url, email, name, sponsorLevel);
    await registerSponsorAfterPay(sponsorLevel)
  };

  const registerSponsorAfterPay = async (sponsorLevel) => {

    const body = {
      eventId: props.eventId,
      firstName: props.firstName,
      lastName: props.lastName,
      companyName: props.companyName,
      country: props.country,
      stateProvince: props.stateProvince,
      zipPostal: props.zipPostal,
      workphone: props.workphone,
      email: props.email,
      platinum: props.platinum,
      gold: props.gold,
      silver: props.silver,
      bronze: props.bronze,
      beverage: props.beverage,
      contributing: props.contributing,
      wifi: props.wifi,
      breakoutRoom: props.breakoutRoom,
      breakStation: props.breakStation,
      meal: props.meal,
      elevator: props.elevator,
      technology: props.technology,
      terms: props.terms
    }

    await registerSponsor(body, props.eventId).then(data => {
      if (data.error) {
        
        setErrorMsg(data.error);       
      } else {

        const adminMessage = {
          "subject": `${sponsorLevel} sponsor for the SDSIC 2025 Conference`,
          "sender": { "email": "events@sdsconsortium.com", "name": "events@sdsconsortium.com" },
          "to": [{ "name": "trevor@sdsconsortium.com", "email": "trevor@sdsconsortium.com" }],
          "htmlContent": `From user ${props.email}, <p>from company: ${props.companyName}, has signed up to sponsor the SDSIC 2025 Conference</p>
                          SDSIC event with email: ${props.email}</p>
                          <p>Sponsorship chosen was: ${sponsorLevel}<br/>
                          firstName=${props.firstName}<br/>
                          lastName=${props.lastName}<br/>
                          email=${props.email}<br/>
                          workphone=${props.workphone}<br/>
                          companyName=${props.companyName}<br/>
                          address=${props.address}<br/>
                          city=${props.city}<br/>
                          terms=${props.terms}<br/>
                          country=${props.country}<br/>
                          stateProvince=${props.stateProvince}<br/>
                          zipPostal=${props.zipPostal}</p>`
        }

        sendMessage(adminMessage).then(data => { });

        const attendeeMessage = {
          "subject": `Thank you for becoming a ${sponsorLevel} sponsor for the SDSIC 2025 Conference`,
          "sender": { "email": "events@sdsconsortium.com", "name": "events@sdsconsortium.com" },
          "to": [{ "name": `${props.email}`, "email": `${props.email}` }],
          "htmlContent": `Hi ${props.firstName}, <p>You have been registered as the contact for the SDSIC 2025 Conference
                           with email: ${props.email}. Your sponsorship is for a <b>${sponsorLevel} sponsor</b>.</p>                                                                        
                          <p>Someone from our SDSIC planning committee will be in touch shortly.<br/>
                          <p>If your sponsorship includes complimentary passes, we will be in touch to help with registering those attendees.<br/>
                          <p>If not, you will still need to register for this event to obtain your conference pass upon checkin.</p>
                          <p>               
                            All meetings and consortium activities will take place at the:<br/>
                            <b>Westin Chattanooga</b><br/>
                            <b>801 Pine Street, Chattanooga, TN 37402</b><br/>
                            Phone: <b>423.531.4653</b><br/>
                          </p>
                          <p>
                            Special hotel rate of <b>$209.00 USD per night</b>. Link below.
                          </p>
                          <p>
                            You will find the information for your online reservation below. If you have questions or need help with the link, please do not hesitate to ask. We appreciate your business and look forward to a successful event.<br/>
                          </p>
                          <p>
                            <b>Event Summary:</b><br/>
                            Start Date: <b>Sunday, April 27, 2025</b><br/>
                            End Date: <b>Thursday, May 01, 2025</b><br/>
                            Hotel(s) offering your special group rate:<br/>
                            • The Westin Chattanooga for <b>$209.00 USD per night</b><br/>
                            Last Day to Book: <b>Monday, April 07, 2025</b><br/>
                            Here's your reservation link to make reservations:<br/>
                            <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1729605643946&key=GRP&guestreslink2=true">
                              Book your group rate for MESA Associates Substation Design Solution (SDS)
                            </a>
                          </p>
                          <p>
                            Thank you!<br/>
                            <b>The SDSIC Leadership Team</b>
                          </p>`
        }

        sendMessage(attendeeMessage).then(data => { });
      }
    });
  }

  const sendEmailReceipt = async (receiptUrl, receiptEmail, receiptName, sponsorLevel) => {

    const mailMessage = {
      "subject": `Your receipt for the ${sponsorLevel} sponsorship for the SDSIC 2025 Conference`,
      "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
      "to": [{ "name": `${receiptEmail}`, "email": `${receiptEmail}` }],
      "htmlContent": `  Hi ${name}, <p>Thank you for your payment for the ${sponsorLevel} sponsor for the SDSIC 2025 Conference.</p>
                        <p>your payment was successful made by ${receiptEmail} and you can find a link to your receipt below<br/>
                        Get your receipt here: ${receiptUrl}     <br/>                 
                        Someone from our SDSIC planning committee will be in touch with ${props.email} shortly.</p>
                          If your sponsorship includes complimentary passes, we will contact ${props.email} to help with registering those attendees.
                          If not, whomever is going to attend will still need to register for this event to obtain a conference pass upon checkin.
                        We all appreciate your support!
                        keep in touch at www.sdsconsortium.com<br/>
                        Your SDSIC Leadership team</p>`
    }


    await sendMessage(mailMessage).then(data => { });

  };

  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay with card</span>
      </h4>
      <form onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className="form-control"
              // value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email (for payment receipt)</label>
            <input
              id="cc-email"
              type="text"
              className="form-control"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <hr className="mb-4" />
        <button className="btn btn-dark w-100" type="submit" From user ={loading}>
          {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY $ ${props.amount} USD`}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}