import React, { Component } from "react";

class PolicyStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {

        return (
            <>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            
                            <h4 className="mbr-header__text">Can I transfer my SDSIC 2025 pass to someone else?</h4> 
                            <p>
                                <ul>
                                    <li>To transfer your SDSIC 2025 Conference Pass, send an email request to the SDSIC Registration Team at events@sdsconsortium.com no later than Friday, March 21, 2025, by 11:59 PM EST</li>
                                    <li>All valid transfer requests will be assessed a $50 USD transfer fee and should be requested via email to the SDSIC Registration Team by Friday, March 21, 2025 (by 11:59 PM EST). Transfer requests received after March 25, 2025, are subject to approval. The person receiving the transfer must not currently be registered for SDSIC 2025 at the time of the transfer request. All transfer requests must be received via email from the original attendee.</li>
                                    <li>For any questions or additional information surrounding transferring your SDSIC 2025 Pass please contact the SDSIC Registration Team at events@sdsconsortium.com.</li>
                                </ul>
                            </p>
                            <h4 className="mbr-header__text">What is the cancellation policy?</h4> 
                            <p>
                                <ul>
                                    <li>To cancel your SDSIC 2025 Pass, send an email request to the SDSIC Registration Team at events@sdsconsortium.com no later than Friday, March 7, 2025, by 11:59 PM EST.</li>
                                    <li>All valid cancellations received prior to Friday, March 7, 2025, will be assessed a $50 USD cancellation fee. After Friday, March 7 11:59 PM EST, 2025 all registration fees are non-refundable. Conference no-shows are ineligible for a refund.</li>
                                    <li>For any questions or additional information surrounding canceling your SDSIC 2025 Pass please contact the SDSIC Registration Team at events@sdsconsortium.com</li>
                                </ul>
                            </p>
                            <h4 className="mbr-header__text">Is the cost of hotel accommodation included in the registration fee?</h4> 
                            <p>
                                <ul>
                                    <li>No. Hotel accommodations for SDSIC 2025 event are not included in the registration fee and must be paid at your own expense. Discounted partner hotel rates are available as part of the registration process.</li>
                                </ul>
                            </p>
                            
                            <p>
                                <b>Changes to This Privacy Policy.</b> This Privacy Policy is subject to occasional revision, and if we make any substantial changes in the way we use your information, we will notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site. Any material changes to this Privacy Policy will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site. These changes will be effective immediately for new users of our Site. Changes to this Privacy Policy may affect our use of information that you provided us prior to our notification to you of the changes. If you do not wish to permit changes in our use of your information, you must notify us prior to the effective date of the changes that you wish to deactivate your account with us. Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to such changes.
                            </p>
                            <p>
                                <b>Contact Information.</b> We welcome your comments or questions regarding this Privacy Policy. Please e-mail us.
                            </p>
                        </div>
                    </div>
                </div>

            </>
        )
    };
}


export default PolicyStatement;