import React, { useState } from 'react';
import './stripe.css'
import { Tab, Row, Col, Nav, Form, Button, Card } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutSponsorForm from './CheckoutSponsorForm';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Burns_and_McDonnell_Logo from '../images/BMBanner.png';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_c637DGW9RSU2FyxZsqd0JvEb");
// const stripePromise = loadStripe("pk_test_Rsw8DhHJSiqhyGWrbqoN43p7");

const successMessage = (receiptUrl, receiptEmail) => {
    return (
        <div className="success-msg">
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
            <div className="title">Payment Successful</div>
            <br />
            <p><a href={receiptUrl} target={"_blank"}>Print your receipt here</a></p>
            <p>A receipt has been emailed to <b>{receiptEmail} </b><br />
                <i>Please check your spam folder if you don't see the email in a few minutes.</i></p>
        </div>
    )
}



function FallSponsorRegistration() {
    const eventId = "6261a6116b020bed17a90d48"
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [receiptUrl, setReceiptUrl] = useState('');
    const [receiptEmail, setReceiptEmail] = useState('');
    const [receiptName, setReceiptName] = useState('');
    const [key, setKey] = useState('nameaddress');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [workphone, setWorkphone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [terms, setTerms] = useState(false);
    const [country, setCountry] = useState('');
    const [stateProvince, setStateProvince] = useState('');
    const [zipPostal, setZipPostal] = useState('');

    const [gold, setGold] = useState(false);
    const [evening, setEvening] = useState(false);
    const [silver, setSilver] = useState(false);
    const [bronze, setBronze] = useState(false);
    const [breakfast, setBreakfast] = useState(false);
    const [lunch, setLunch] = useState(false);
    const [lanyard, setLanyard] = useState(false);
    const [snacks, setSnacks] = useState(false);
    const [contributing, setContributing] = useState(false);
    const [door, setDoor] = useState(false);

    const [error, setError] = useState('');

    const [sponsorCost, setSponsorCost] = useState(0);

    const handleSelect = (key) => {
        let handleError = ""
        if (firstName === "") { handleError = 'First Name is required' }
        if (lastName === "") { handleError = 'Last Name is required' }
        if (email === "") { handleError = 'Email is required' }
        if (address === "") { handleError = "Address is required" }
        if (city === "") { handleError = "City is required" }
        if (companyName === "") { handleError = 'Company Name is required' }
        if (terms === false) { handleError = 'You must agree to the SDSIC terms' }
        if (country === "") { handleError = 'Country is required' }
        if (stateProvince === "") { handleError = 'State or Province is required' }
        if (zipPostal === "") { handleError = 'Zip or Postal Code is required' }
        if (gold === false & silver === false & bronze === false & breakfast === false
            & lunch === false & lanyard === false & snacks === false & contributing === false
            & door === false) { handleError = 'You must choose at least one sponsorship type to continue' }

        if (handleError !== "") {
            setError(handleError)
        } else {
            setKey(key)
        }

    }

    const selectCountry = (val) => {
        setCountry(val);
        setError("");
    }

    const selectRegion = (val) => {
        setStateProvince(val);
        setError("");
    }

    const showError = () => {
        return (
            <div className="alert alert-danger">
                {error}
            </div>
        )
    };


    const cart = () => {
        return (<React.Fragment>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your cart</span>
                <span className="badge bg-secondary badge-pill">3</span>
            </h4>
            <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">SDSIC Conference</h6>
                        <h6 className="my-0">{gold && 'Gold Sponsorship'}</h6>
                        <h6 className="my-0">{silver && 'Silver Sponsorship'}</h6>
                        <h6 className="my-0">{bronze && 'Bronze Sponsorship'}</h6>
                        <h6 className="my-0">{breakfast && 'Breakfast Sponsorship'}</h6>
                        <h6 className="my-0">{lunch && 'Lunch Sponsorship'}</h6>
                        <h6 className="my-0">{lanyard && 'Lanyard Sponsorship'}</h6>
                        <h6 className="my-0">{snacks && 'Snack Sponsorship'}</h6>
                        <h6 className="my-0">{contributing && 'Contributing Sponsorship'}</h6>
                        <h6 className="my-0">{door && 'Door Prize Sponsorship'}</h6>
                        <small className="text-muted">Fall Kansas City Event 2022</small>
                    </div>
                    <span className="text-muted">{currencyFormat(sponsorCost)}</span>
                </li>

                {/* <li className="list-group-item d-flex justify-content-between bg-light">
        <div className="text-success">
          <h6 className="my-0">Promo code</h6>
          <small></small>
        </div>
        <span className="text-success">-₹500</span>
      </li> */}
                <li className="list-group-item d-flex justify-content-between">
                    <span>Total (USD)</span>
                    <strong>{currencyFormat(sponsorCost)}</strong>
                </li>
            </ul>
        </React.Fragment>)
    }

    const handleChange = name => event => {

        switch (name) {
            case 'firstName': {
                setFirstName(event.target.value)
                setError("");
                break;
            }
            case 'lastName': {
                setLastName(event.target.value)
                setError("");
                break;
            }
            case 'email': {
                setEmail(event.target.value)
                setError("");
                break;
            }
            case 'workphone': {
                setWorkphone(event.target.value)
                setError("");
                break;
            }
            case 'companyName': {
                setCompanyName(event.target.value)
                setError("");
                break;
            }
            case 'terms': {
                setTerms(event.target.checked)
                setError("");
                break;
            }
            case 'zipPostal': {
                setZipPostal(event.target.value)
                setError("");
                break;
            }
            case 'address': {
                setAddress(event.target.value)
                setError("");
                break;
            }
            case 'city': {
                setCity(event.target.value)
                setError("");
                break;
            }
            case 'gold': {
                setGold(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(10000)
                    setError("");
                    setSilver(false);
                    setBronze(false);
                    setBreakfast(false);
                    setLunch(false);
                    setLanyard(false);
                    setSnacks(false);
                    setContributing(false);
                    setDoor(false);
                }
                break;
            }
            case 'silver': {
                setSilver(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(6000)
                    setError("");
                    setGold(false);
                    setBronze(false);
                    setBreakfast(false);
                    setLunch(false);
                    setLanyard(false);
                    setSnacks(false);
                    setContributing(false);
                    setDoor(false);
                }
                break;

            }
            case 'bronze': {
                setBronze(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(5000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBreakfast(false);
                    setLunch(false);
                    setLanyard(false);
                    setSnacks(false);
                    setContributing(false);
                    setDoor(false);
                }
                break;
            }
            case 'breakfast': {
                setBreakfast(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(3000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setLunch(false);
                    setLanyard(false);
                    setSnacks(false);
                    setContributing(false);
                    setDoor(false);
                }
                break;
            }
            case 'lunch': {
                setLunch(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(4500)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBreakfast(false);
                    setLanyard(false);
                    setSnacks(false);
                    setContributing(false);
                    setDoor(false);
                }
                break;
            }
            case 'lanyard': {
                setLanyard(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(4000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBreakfast(false);
                    setLunch(false);
                    setSnacks(false);
                    setContributing(false);
                    setDoor(false);
                }
                break;
            }
            case 'snacks': {
                setSnacks(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(3000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBreakfast(false);
                    setLunch(false);
                    setLanyard(false);
                    setContributing(false);
                    setDoor(false);
                }
                break;
            }
            case 'contributing': {
                setContributing(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(500)
                    setError(""); setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBreakfast(false);
                    setLunch(false);
                    setLanyard(false);
                    setSnacks(false);
                    setDoor(false);

                }
                break;
            }
            case 'door': {
                setDoor(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(250)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBreakfast(false);
                    setLunch(false);
                    setLanyard(false);
                    setSnacks(false);
                    setContributing(false);
                }
                break;
            }
            // default: {
            //     setError("");
            //     break;
            // }
        }
    };

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <div className="container">
            <div className="py-5 text-center">

                <h2>SDSIC Kansas City Conference 2022 Sponsorship Registration</h2>
                <h4>Hosted By: </h4><img className="d-block mx-auto mb-4" src={Burns_and_McDonnell_Logo} alt="" height="72" />

            </div>

            <Tab.Container activeKey={key} onSelect={handleSelect} >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="nameaddress">Name & Address</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="review">Review</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="payment">Payment</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="nameaddress">
                                <div className="row s-box">
                                    {error && showError()}
                                    <Form >
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    placeholder="First name"
                                                    name="firstName"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("firstName")}
                                                    value={firstName}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Email"
                                                    name="email"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("email")}
                                                    value={email}
                                                />
                                                <br />
                                                <CountryDropdown
                                                    className="form-control"
                                                    name="country"
                                                    placeholder="Country"
                                                    value={country}
                                                    onChange={(val) => selectCountry(val)} />

                                                <br />
                                                <Form.Control
                                                    placeholder="Work Phone"
                                                    name="phone"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("workphone")}
                                                    value={workphone}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Address"
                                                    name="address"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("address")}
                                                    value={address}
                                                />

                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Last name"
                                                    name="lastName"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("lastName")}
                                                    value={lastName}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Company"
                                                    name="company"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("companyName")}
                                                    value={companyName}
                                                />
                                                <br />
                                                <RegionDropdown
                                                    className="form-control"
                                                    placeholder="State / Province"
                                                    country={country}
                                                    value={stateProvince}
                                                    onChange={(val) => selectRegion(val)} />

                                                <br />
                                                <Form.Control
                                                    placeholder="City"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("city")}
                                                    value={city}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Zip/Postal Code"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("zipPostal")}
                                                    value={zipPostal}
                                                />
                                            </Col>
                                        </Row>


                                        <br />

                                        <h5>Choose a Sponsorship Level</h5>

                                        <Row>
                                            <Col>
                                            <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    disabled
                                                                    checked={evening}
                                                                    onChange={handleChange('evening')}
                                                                    label={`EVENING EVENT SPONSOR (Multiple Available)`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li><del>Full Control of hosting evening event</del></li>
                                                                <li><del>Any Location and event selection preferred by sponsor team</del></li>                                                                
                                                                <li><del>3 complimentary passes</del></li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br/>
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={gold}
                                                                    onChange={handleChange('gold')}
                                                                    label={`GOLD SPONSOR $10,000 (Multiple Available)`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Approved sponsor-provided a promotional video in General Session</li>
                                                                <li>Logo prominently displayed on conference signage</li>
                                                                <li>Logo listed on website</li>
                                                                <li>Logo recognition on name badge</li>
                                                                <li>Logo recognition on screen in General Session</li>
                                                                <li>3 complimentary passes</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={silver}
                                                                    onChange={handleChange('silver')}
                                                                    label={`SILVER SPONSOR $6,000 (Multiple Available)`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Logo prominently displayed on conference signage</li>
                                                                <li>Logo listed on website</li>
                                                                <li>Logo recognition on name badge</li>
                                                                <li>Logo recognition on screen in General Session</li>
                                                                <li>2 complimentary passes</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={bronze}
                                                                    onChange={handleChange('bronze')}
                                                                    label={`BRONZE SPONSOR $5,000 (Multiple Available)`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Logo prominently displayed on conference signage</li>
                                                                <li>Logo listed on website</li>
                                                                <li>Logo recognition on screen in General Session</li>
                                                                <li>2 complimentary passes</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={breakfast}
                                                                    onChange={handleChange('breakfast')}
                                                                    label={`BREAKFAST SPONSOR $3,000 (3 Available)`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Approved marketing collateral provided by company on luncheon tables (quantity based on attendance)</li>
                                                                <li>Logo listed on website</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                            </Col>



                                            <Col>
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={lunch}
                                                                    onChange={handleChange('lunch')}
                                                                    label='LUNCH SPONSOR $4,500 (3 Available)'
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Approved marketing collateral provided by company on luncheon tables (quantity based on attendance)</li>
                                                                <li>Logo recognition on table tent cards</li>
                                                                <li>Rolling banner recognition at conference</li>
                                                                <li>Logo listed on website</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    disabled
                                                                    type="checkbox"
                                                                    checked={lanyard}
                                                                    onChange={handleChange('lanyard')}
                                                                    label='LANYARD SPONSOR $4,000 (EXCLUSIVE)'
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li><del> Company name and/or logo on lanyards</del> </li>
                                                                <li><del>Logo listed on website</del> </li>
                                                                <li><del>Logo recognition on screen in General Session</del> </li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>

                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={snacks}
                                                                    onChange={handleChange('snacks')}
                                                                    label='COFFEE/SNACKS BREAK SPONSOR $3,000 (Last 1 Available) '
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Company name and/or logo listed on napkins</li>
                                                                <li>Logo listed on website</li>
                                                                <li>Logo recognition on screen in General Session</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>

                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={contributing}
                                                                    onChange={handleChange('contributing')}
                                                                    label='CONTRIBUTING SPONSOR $500 (Multiple Available)'
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Company logo displayed on sponsor page of printed program</li>
                                                                <li>Logo listed on website</li>
                                                                <li>Logo recognition on screen in General Session</li>
                                                                <li>One exhibit table location at event</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>

                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={door}
                                                                    onChange={handleChange('door')}
                                                                    label='DOOR PRIZE SPONSOR $250+ (Multiple Available)'
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Company name recognition in General Session during the distribution of door prizes</li>
                                                                <li>Sponsor-donated item valued at $250 or more</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>


                                            </Col>
                                        </Row>
                                        <br />
                                        <Form.Group id="formGridCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                checked={terms}
                                                onChange={handleChange('terms')}
                                                label='I agree to the use of my personal information as described in the SDSIC '
                                            />
                                            <span><a href="/privacystatement" target="_blank">Privacy Statement.</a></span>
                                        </Form.Group>
                                    </Form>
                                    {error && showError()}
                                    <div className="col-md-5 order-md-2 mb-4">
                                        <Button variant="success" onClick={() => handleSelect("review")}>Next</Button>
                                    </div>

                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="review">
                                <div className="row s-box">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p><i>Please review your entries carefully.</i></p>
                                            <p>First Name:   <b>{firstName}</b></p>
                                            <p>Last Name:   <b>{lastName}</b></p>
                                            <p>Email:  <b>{email}</b></p>
                                            <p>Company Name:    <b>{companyName}</b></p>
                                            <p>Country: <b>{country}</b></p>
                                            <p>Region:  <b>{stateProvince}</b></p>
                                            <p>Work Phone:  <b>{workphone}</b></p>
                                            {gold && <p>Sponsorship type Chosen: <b>Gold Sponsor</b></p>}
                                            {silver && <p>Sponsorship type Chosen: <b>Silver Sponsor</b></p>}
                                            {bronze && <p>Sponsorship type Chosen: <b>Bronze Sponsor</b></p>}
                                            {breakfast && <p>Sponsorship type Chosen: <b>Breakfast Sponsor</b></p>}
                                            {lunch && <p>Sponsorship type Chosen: <b>Lunch Sponsor</b></p>}
                                            {lanyard && <p>Sponsorship type Chosen: <b>Lanyard Sponsor</b></p>}
                                            {snacks && <p>Sponsorship type Chosen: <b>Snack Sponsor</b></p>}
                                            {contributing && <p>Sponsorship type Chosen: <b>Contributing Sponsor</b></p>}
                                            {door && <p>Sponsorship type Chosen: <b>Door Prize Sponsor</b></p>}
                                            {sponsorCost && <p>Sponsorship Cost is: <b>{currencyFormat(sponsorCost)}</b></p>}
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 order-md-1">
                                            <Button variant="primary" onClick={() => handleSelect("nameaddress")}>Back</Button>
                                        </div>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            {sponsorCost && <Button variant="success" onClick={() => handleSelect("payment")}>Payment</Button>}
                                            {!sponsorCost && <Button variant="secondary" disabled >Payment</Button>}
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>


                            <Tab.Pane id="3" eventKey="payment">
                                <div className="row s-box">
                                    {paymentCompleted ? successMessage(receiptUrl, receiptEmail, receiptName) : <React.Fragment>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            {!error && cart()}
                                        </div>
                                        <div className="col-md-7 order-md-1">
                                            {error && showError()}

                                            {!error && <Elements stripe={stripePromise}>
                                                <CheckoutSponsorForm
                                                    amount={sponsorCost}
                                                    setPaymentCompleted={setPaymentCompleted}
                                                    setReceiptUrl={setReceiptUrl}
                                                    setReceiptEmail={setReceiptEmail}
                                                    setReceiptName={setReceiptName}
                                                    eventId={eventId}
                                                    firstName={firstName}
                                                    lastName={lastName}
                                                    email={email}
                                                    workphone={workphone}
                                                    companyName={companyName}
                                                    address={address}
                                                    city={city}
                                                    terms={terms}
                                                    country={country}
                                                    stateProvince={stateProvince}
                                                    zipPostal={zipPostal}
                                                    gold={gold}
                                                    silver={silver}
                                                    bronze={bronze}
                                                    breakfast={breakfast}
                                                    lunch={lunch}
                                                    lanyard={lanyard}
                                                    snacks={snacks}
                                                    contributing={contributing}
                                                    door={door}
                                                    sponsorCost={sponsorCost}
                                                />
                                            </Elements>
                                            }
                                        </div>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            <br />
                                            <Button variant="success" onClick={() => handleSelect("review")}>Back</Button>
                                        </div>
                                    </React.Fragment>}


                                </div>


                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>



        </div>
    );
}

export default FallSponsorRegistration;
