import React, { useState } from 'react';
import '../event/stripe.css'
import { Tab, Row, Col, Nav, Form, Button, Card } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutSponsorForm from './CheckoutSponsorForm';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import BV_LOGO from '../images/Black_&_Veatch-Logo.png';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_c637DGW9RSU2FyxZsqd0JvEb");
// const stripePromise = loadStripe("pk_test_Rsw8DhHJSiqhyGWrbqoN43p7");

const successMessage = (receiptUrl, receiptEmail) => {
    return (
        <div className="success-msg">
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
            <div className="title">Payment Successful</div>
            <br />
            <p><a href={receiptUrl} target={"_blank"}>Print your receipt here</a></p>
            <p>A receipt has been emailed to <b>{receiptEmail} </b><br />
                <i>Please check your spam folder if you don't see the email in a few minutes.</i></p>
        </div>
    )
}



function KansasSponsorReg() {
    const eventId = "655bb358b24d7dfa488b46af"
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [receiptUrl, setReceiptUrl] = useState('');
    const [receiptEmail, setReceiptEmail] = useState('');
    const [receiptName, setReceiptName] = useState('');
    const [key, setKey] = useState('nameaddress');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [workphone, setWorkphone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [terms, setTerms] = useState(false);
    const [country, setCountry] = useState('');
    const [stateProvince, setStateProvince] = useState('');
    const [zipPostal, setZipPostal] = useState('');

    const [gold, setGold] = useState(false);
    const [evening, setEvening] = useState(false);
    const [silver, setSilver] = useState(false);
    const [bronze, setBronze] = useState(false);
    const [beverage, setBeverage] = useState(false);
    const [contributing, setContributing] = useState(false);
    const [wifi, setWifi] = useState(false);
    const [breakoutRoom, setBreakoutRoom] = useState(false);
    const [breakStation, setBreakStation] = useState(false);
    const [meal, setMeal] = useState(false);
    const [escalator, setEscalator] = useState(false);
    const [notebook, setNotebook] = useState(false);
    const [technology, setTechnology] = useState(false);
    const [floorCling, setFloorCling] = useState(false);


    const [error, setError] = useState('');

    const [sponsorCost, setSponsorCost] = useState(0);

    const handleSelect = (key) => {
        let handleError = ""
        if (firstName === "") { handleError = 'First Name is required' }
        if (lastName === "") { handleError = 'Last Name is required' }
        if (email === "") { handleError = 'Email is required' }
        if (address === "") { handleError = "Address is required" }
        if (city === "") { handleError = "City is required" }
        if (companyName === "") { handleError = 'Company Name is required' }
        if (terms === false) { handleError = 'You must agree to the SDSIC terms' }
        if (country === "") { handleError = 'Country is required' }
        if (stateProvince === "") { handleError = 'State or Province is required' }
        if (zipPostal === "") { handleError = 'Zip or Postal Code is required' }
        if (gold === false & silver === false & bronze === false
            & beverage === false & breakoutRoom === false & contributing === false
            & wifi === false & breakStation === false & meal === false & escalator === false
            & notebook === false & technology === false & floorCling === false) { handleError = 'You must choose at least one sponsorship type to continue' }

        if (handleError !== "") {
            setError(handleError)
        } else {
            setKey(key)
        }

    }

    const selectCountry = (val) => {
        setCountry(val);
        setError("");
    }

    const selectRegion = (val) => {
        setStateProvince(val);
        setError("");
    }

    const showError = () => {
        return (
            <div className="alert alert-danger">
                {error}
            </div>
        )
    };


    const cart = () => {
        return (<React.Fragment>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your cart</span>
                <span className="badge bg-secondary badge-pill">3</span>
            </h4>
            <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">SDSIC Conference</h6>
                        <h6 className="my-0">{gold && 'Gold Sponsorship'}</h6>
                        <h6 className="my-0">{silver && 'Silver Sponsorship'}</h6>
                        <h6 className="my-0">{bronze && 'Bronze Sponsorship'}</h6>
                        <h6 className="my-0">{beverage && 'Beverage Station Sponsorship'}</h6>
                        <h6 className="my-0">{contributing && 'Contributing Sponsorship'}</h6>
                        <h6 className="my-0">{wifi && 'WIFI Sponsorship'}</h6>
                        <h6 className="my-0">{breakoutRoom && 'Breakout Room Sponsorship'}</h6>
                        <h6 className="my-0">{breakStation && 'Break Station Sponsorship'}</h6>
                        <h6 className="my-0">{meal && 'Meal Sponsorship'}</h6>
                        <h6 className="my-0">{escalator && 'Escalator Sponsorship'}</h6>
                        <h6 className="my-0">{notebook && 'Notebook Sponsorship'}</h6>
                        <h6 className="my-0">{technology && 'Technology Sponsorship'}</h6>
                        <h6 className="my-0">{floorCling && 'Floor Cling Sponsorship'}</h6>
                        <small className="text-muted">Fall Kansas City Event 2022</small>
                    </div>
                    <span className="text-muted">{currencyFormat(sponsorCost)}</span>
                </li>

                {/* <li className="list-group-item d-flex justify-content-between bg-light">
        <div className="text-success">
          <h6 className="my-0">Promo code</h6>
          <small></small>
        </div>
        <span className="text-success">-₹500</span>
      </li> */}
                <li className="list-group-item d-flex justify-content-between">
                    <span>Total (USD)</span>
                    <strong>{currencyFormat(sponsorCost)}</strong>
                </li>
            </ul>
        </React.Fragment>)
    }

    const handleChange = name => event => {

        // eslint-disable-next-line default-case
        switch (name) {
            case 'firstName': {
                setFirstName(event.target.value)
                setError("");
                break;
            }
            case 'lastName': {
                setLastName(event.target.value)
                setError("");
                break;
            }
            case 'email': {
                setEmail(event.target.value)
                setError("");
                break;
            }
            case 'workphone': {
                setWorkphone(event.target.value)
                setError("");
                break;
            }
            case 'companyName': {
                setCompanyName(event.target.value)
                setError("");
                break;
            }
            case 'terms': {
                setTerms(event.target.checked)
                setError("");
                break;
            }
            case 'zipPostal': {
                setZipPostal(event.target.value)
                setError("");
                break;
            }
            case 'address': {
                setAddress(event.target.value)
                setError("");
                break;
            }
            case 'city': {
                setCity(event.target.value)
                setError("");
                break;
            }
            case 'gold': {
                setGold(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(12000)
                    setError("");
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'silver': {
                setSilver(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(8000)
                    setError("");
                    setGold(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'bronze': {
                setBronze(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(5000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'beverage': {
                setBeverage(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(5000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'contributing': {
                setContributing(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(1000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'wifi': {
                setWifi(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(10000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'breakoutRoom': {
                setBreakoutRoom(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(7000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'breakStation': {
                setBreakStation(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(3000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'meal': {
                setMeal(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(12500)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'escalator': {
                setEscalator(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(3250)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setNotebook(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'notebook': {
                setNotebook(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(1500)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setTechnology(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'technology': {
                setTechnology(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(1000)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setFloorCling(false);
                }
                break;
            }
            case 'floorCling': {
                setFloorCling(event.target.checked)
                if (event.target.checked === true) {
                    setSponsorCost(350)
                    setError("");
                    setGold(false);
                    setSilver(false);
                    setBronze(false);
                    setBeverage(false);
                    setContributing(false);
                    setWifi(false);
                    setBreakoutRoom(false);
                    setBreakStation(false);
                    setMeal(false);
                    setEscalator(false);
                    setNotebook(false);
                    setTechnology(false);
                }
                break;
            }
        }
    };

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <div className="container">
            <div className="py-5 text-center">

                <h2>Registration for the SDSIC Spring Event 2024, Kansas City, MO</h2>
                <h2>Hosted By: </h2>
                <img className="d-block mx-auto mb-1" src={BV_LOGO} alt="" height="150" />
                <br />
                <h4>Download our event sponsorship prospectus below</h4>
                
                <a className="btn btn-success" href="/images/SDSIC%20Sponsorship%20Prospectus%202.9.24.pdf" target="_blank">Download sponsorship Prospectus </a>


            </div>


            <Tab.Container activeKey={key} onSelect={handleSelect} >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="nameaddress">Name & Address</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="review">Review</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="payment">Payment</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="nameaddress">
                                <div className="row s-box">
                                    {error && showError()}
                                    <Form >
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    placeholder="First name"
                                                    name="firstName"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("firstName")}
                                                    value={firstName}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Email"
                                                    name="email"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("email")}
                                                    value={email}
                                                />
                                                <br />
                                                <CountryDropdown
                                                    className="form-control"
                                                    name="country"
                                                    placeholder="Country"
                                                    value={country}
                                                    onChange={(val) => selectCountry(val)} />

                                                <br />
                                                <Form.Control
                                                    placeholder="Work Phone"
                                                    name="phone"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("workphone")}
                                                    value={workphone}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Address"
                                                    name="address"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("address")}
                                                    value={address}
                                                />

                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Last name"
                                                    name="lastName"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("lastName")}
                                                    value={lastName}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Company"
                                                    name="company"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("companyName")}
                                                    value={companyName}
                                                />
                                                <br />
                                                <RegionDropdown
                                                    className="form-control"
                                                    placeholder="State / Province"
                                                    country={country}
                                                    value={stateProvince}
                                                    onChange={(val) => selectRegion(val)} />

                                                <br />
                                                <Form.Control
                                                    placeholder="City"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("city")}
                                                    value={city}
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Zip/Postal Code"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("zipPostal")}
                                                    value={zipPostal}
                                                />
                                            </Col>
                                        </Row>


                                        <br />

                                        <h5>Choose a Sponsorship Level</h5>

                                        <Row>
                                            <Col>
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    disabled
                                                                    checked={evening}
                                                                    onChange={handleChange('evening')}
                                                                    label={`EVENING EVENT SPONSOR`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <p><i><b>Sold Out for this event</b></i></p>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={gold}
                                                                    onChange={handleChange('gold')}
                                                                    label={`GOLD SPONSOR $12,000`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Conference banner branding on digital displays throughout conference hall</li>
                                                                <li>6ft table/exhibit space with black drapping</li>
                                                                <li>Photo for reference on page 7</li>
                                                                <li>2 chairs, 1 wastebasket, 1 outlet</li>
                                                                <li>Option to bring one pop-up display no larger</li>
                                                                <li>than 33.5”x79”</li>
                                                                <li>Window cling display behind table exhibit space</li>
                                                                <li>Branded column next to 6ft display table</li>
                                                                <li>Slide show recognition during general session</li>
                                                                <li>Verbal on-stage recognition during general session</li>
                                                                <li>Logo on website and social media posts</li>
                                                                <li>Three complimentary conference registrations ($1,650 value)</li>
                                                                <li>Customized post-event eblast to attendees</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={silver}
                                                                    onChange={handleChange('silver')}
                                                                    label={`SILVER SPONSOR $8,000`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Conference banner branding on digital displays</li>
                                                                <li>Slide show recognition during general session</li>
                                                                <li>Verbal on-stage recognition</li>
                                                                <li>Logo on website and social media posts</li>
                                                                <li>Customized post-event eblast to attendees</li>
                                                                <li>Two complimentary conference registrations ($1,100 value)</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={bronze}
                                                                    onChange={handleChange('bronze')}
                                                                    label={`BRONZE SPONSOR $5,000`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Conference banner branding on digital displays</li>
                                                                <li>Slide show recognition during general session</li>
                                                                <li>Verbal on-stage recognition</li>
                                                                <li>Logo on website and social media posts</li>
                                                                <li>One complimentary conference registration ($550 value)</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={breakoutRoom}
                                                                    onChange={handleChange('breakoutRoom')}
                                                                    label={`BREAKOUT ROOM SPONSOR $7,000`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>*Each sponsor will be able to select their breakout sponsorship topic based on topic availability.</li>
                                                                <li>• Conference banner branding on digital displays</li>
                                                                <li>• 2-minute word from sponsor at breakout session</li>
                                                                <li>• Slide show recognition during general session</li>
                                                                <li>• Verbal on-stage recognition</li>
                                                                <li>• Option to place a giveaway item on each table of breakout room *Sponsor must provide giveaway item to Black & Veatch by April 1, 2024</li>
                                                                <li>• Logo on screen and on podium graphic during sessions–3 days, 3 hours each day – photo for reference on page 8</li>
                                                                <li>• Logo on digital display in main hallway outside of breakout rooms recognizing breakout sponsors</li>
                                                                <li>• 1 full conference registration ($550 value)</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>

                                            </Col>


                                            <Col>
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    disabled
                                                                    checked={escalator}
                                                                    onChange={handleChange('escalator')}
                                                                    label='ESCALATOR SPONSOR $3,250'
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Exclusive sponsorship for the Kansas City Convention Center</li>
                                                                <li>Branded escalator center run at main entrance of conference center – all attendees will see signage upon entry – photo for reference on page 10</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    disabled
                                                                    checked={notebook}
                                                                    onChange={handleChange('notebook')}
                                                                    label='NOTEBOOK $1,500'
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Sponsor provides branded notebooks to be handed out to each conference attendee during badge pickup (estimated 300 attendees-notebooks have to be shipped to arrive at Black & Veatch by April 1, 2024)</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>

                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={technology}
                                                                    onChange={handleChange('technology')}
                                                                    label='TECHNOLOGY SPONSOR $1,000 '
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Logo recognition on conference website as the Technology Sponsor, recognition on digital displays during conference</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={wifi}
                                                                    onChange={handleChange('wifi')}
                                                                    label='WIFI SPONSOR $10,000 '
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Logo recognition on conference website as the Wi-Fi sponsor</li>
                                                                <li>Signage/logo recognition throughout conference during all 4 days</li>
                                                                <li>Company/logo recognition on initial internet access screen for all conference attendees</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>

                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">
                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={contributing}
                                                                    onChange={handleChange('contributing')}
                                                                    label='CONTRIBUTING SPONSOR $1000 (Multiple Available)'
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Slide show recognition during keynote</li>
                                                                <li>Verbal on-stage recognition</li>
                                                                <li>Logo on website</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                                <br />
                                                <Card sx={{ minWidth: 275 }}>
                                                    <Form.Group id="formGridCheckbox">

                                                        <Card.Header>
                                                            <h5>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={meal}
                                                                    onChange={handleChange('meal')}
                                                                    label={`MEAL SPONSOR $12,500`}
                                                                />
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <span>
                                                                <li>Sponsor can select day and meal (breakfast or lunch) based on availability</li>
                                                                <li>Conference banner branding on digital displays</li>
                                                                <li>Signage by sponsored table during meal</li>
                                                                <li>Option to provide 2 minute company video or speaking introduction during lunch to all conference attendees</li>
                                                                <li>Slide show recognition during general session</li>
                                                                <li>Verbal on-stage recognition</li>
                                                                <li>Logo on website</li>
                                                                <li>Customized post conference eblast to attendees</li>
                                                                <li>3 full conference registrations ($1,650 value)</li>
                                                            </span>
                                                        </Card.Body>
                                                    </Form.Group>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <br />

                                        <Form.Group id="formGridCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                checked={terms}
                                                onChange={handleChange('terms')}
                                                label='I agree to the use of my personal information as described in the SDSIC '
                                            />
                                            <span><a href="/privacystatement" target="_blank">Privacy Statement.</a></span>
                                        </Form.Group>
                                    </Form>
                                    {error && showError()}
                                    <div className="col-md-5 order-md-2 mb-4">
                                        <Button variant="success" onClick={() => handleSelect("review")}>Next</Button>
                                    </div>

                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="review">
                                <div className="row s-box">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p><i>Please review your entries carefully.</i></p>
                                            <p>First Name:   <b>{firstName}</b></p>
                                            <p>Last Name:   <b>{lastName}</b></p>
                                            <p>Email:  <b>{email}</b></p>
                                            <p>Company Name:    <b>{companyName}</b></p>
                                            <p>Country: <b>{country}</b></p>
                                            <p>Region:  <b>{stateProvince}</b></p>
                                            <p>Work Phone:  <b>{workphone}</b></p>
                                            {gold && <p>Sponsorship type Chosen: <b>Gold Sponsor</b></p>}
                                            {silver && <p>Sponsorship type Chosen: <b>Silver Sponsor</b></p>}
                                            {bronze && <p>Sponsorship type Chosen: <b>Bronze Sponsor</b></p>}
                                            {beverage && <p>Sponsorship type Chosen: <b>Beverage Sponsor</b></p>}
                                            {contributing && <p>Sponsorship type Chosen: <b>Contributing Sponsor</b></p>}
                                            {wifi && <p>Sponsorship type Chosen: <b>Wifi Sponsor</b></p>}
                                            {breakoutRoom && <p>Sponsorship type Chosen: <b>Breakout Room Sponsor</b></p>}
                                            {breakStation && <p>Sponsorship type Chosen: <b>Break Station Sponsor</b></p>}
                                            {meal && <p>Sponsorship type Chosen: <b>Meal Sponsor</b></p>}
                                            {escalator && <p>Sponsorship type Chosen: <b>Escalator Sponsor</b></p>}
                                            {notebook && <p>Sponsorship type Chosen: <b>Notebook Sponsor</b></p>}
                                            {technology && <p>Sponsorship type Chosen: <b>Technology Sponsor</b></p>}
                                            {floorCling && <p>Sponsorship type Chosen: <b>Floor Cling Sponsor</b></p>}

                                            {sponsorCost && <p>Sponsorship Cost is: <b>{currencyFormat(sponsorCost)}</b></p>}
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 order-md-1">
                                            <Button variant="primary" onClick={() => handleSelect("nameaddress")}>Back</Button>
                                        </div>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            {sponsorCost && <Button variant="success" onClick={() => handleSelect("payment")}>Payment</Button>}
                                            {!sponsorCost && <Button variant="secondary" disabled >Payment</Button>}
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>


                            <Tab.Pane id="3" eventKey="payment">
                                <div className="row s-box">
                                    {paymentCompleted ? successMessage(receiptUrl, receiptEmail, receiptName) : <React.Fragment>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            {!error && cart()}
                                        </div>
                                        <div className="col-md-7 order-md-1">
                                            {error && showError()}

                                            {!error && <Elements stripe={stripePromise}>
                                                <CheckoutSponsorForm
                                                    amount={sponsorCost}
                                                    setPaymentCompleted={setPaymentCompleted}
                                                    setReceiptUrl={setReceiptUrl}
                                                    setReceiptEmail={setReceiptEmail}
                                                    setReceiptName={setReceiptName}
                                                    eventId={eventId}
                                                    firstName={firstName}
                                                    lastName={lastName}
                                                    email={email}
                                                    workphone={workphone}
                                                    companyName={companyName}
                                                    address={address}
                                                    city={city}
                                                    terms={terms}
                                                    country={country}
                                                    stateProvince={stateProvince}
                                                    zipPostal={zipPostal}
                                                    gold={gold}
                                                    silver={silver}
                                                    bronze={bronze}
                                                    beverage={beverage}
                                                    contributing={contributing}
                                                    wifi={wifi}
                                                    breakoutRoom={breakoutRoom}
                                                    breakStation={breakStation}
                                                    meal={meal}
                                                    escalator={escalator}
                                                    notebook={notebook}
                                                    technology={technology}
                                                    floorCling={floorCling}
                                                    sponsorCost={sponsorCost}
                                                />

                                            </Elements>
                                            }
                                        </div>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            <br />
                                            <Button variant="success" onClick={() => handleSelect("review")}>Back</Button>
                                        </div>
                                    </React.Fragment>}


                                </div>


                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>



        </div>
    );
}

export default KansasSponsorReg;
