import React, { useState } from 'react';
import './stripe.css'
import { Tab, Row, Col, Nav, Form, Button } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import BV_LOGO from '../images/Mesa-Associates-Transparent.png';


import EventInformation from './2025.json';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_c637DGW9RSU2FyxZsqd0JvEb");
// const stripePromise = loadStripe("pk_test_Rsw8DhHJSiqhyGWrbqoN43p7");

const successMessage = (receiptUrl, receiptEmail) => {
    return (
        <div className="success-msg">
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
            <div className="title">Payment Successful</div>
            <br />
            <p><a href={receiptUrl} target={"_blank"}>Print your receipt here</a></p>
            <p>A receipt has been emailed to <b>{receiptEmail} </b><br />
                <i>Please check your spam folder if you don't see the email in a few minutes.</i></p>
        </div>
    )
}

 

function Registration25() {
    const eventId = "673c6cb712555006f0f99b3a"

    const cart = () => {
        return (<React.Fragment>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your cart</span>
                <span className="badge bg-secondary badge-pill">3</span>
            </h4>
            <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">SDSIC 2025 Event</h6>
                        <small className="text-muted">Cost per attendee:</small>
                    </div>
                    <span className="text-muted">$ {EventInformation.cost}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">you have {userList.length} user(s) in your cart</h6>
                        {(userList.length > 0) &&
                            userList.map((user, i) => {
                                return (
                                    <p><small className="text-muted">{`No. ${i + 1} : ${user.email}`}</small></p>
                                )
                            })
                        }

                    </div>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                    <span>Total (USD)</span>
                    <strong>${EventInformation.cost * userList.length}</strong>
                </li>
            </ul>
        </React.Fragment>)
    }

    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [receiptUrl, setReceiptUrl] = useState('');
    const [receiptEmail, setReceiptEmail] = useState('');
    const [receiptName, setReceiptName] = useState('');
    const [key, setKey] = useState('attendees');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [workphone, setWorkphone] = useState('');
    const [day_1, setDay_1] = useState(false);
    const [day_2, setDay_2] = useState(false);
    const [day_3, setDay_3] = useState(false);
    const [day_4, setDay_4] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [industry, setIndustry] = useState('');
    const [role, setRole] = useState('');
    const [jobLevel, setJobLevel] = useState('');
    const [terms, setTerms] = useState(false);
    const [payAgreed, setPayAgreed] = useState(false);
    const [country, setCountry] = useState('');
    const [stateProvince, setStateProvince] = useState('');
    const [zipPostal, setZipPostal] = useState('');
    const [userList, setUserList] = useState([]);
    const [error, setError] = useState('');
    const [sponsorInterest, setSponsorInterest] = useState(false);
    const [accessibility, setAccessibility] = useState(false);


    const handleSelect = (key) => {
        let handleError = ""

        if (key === 'agenda') {
            if (firstName === "") { handleError = 'First Name is required' }
            if (lastName === "") { handleError = 'Last Name is required' }
            if (email === "") { handleError = 'Email is required' }
            if (companyName === "") { handleError = 'Company Name is required' }
            if (industry === "") { handleError = 'Company Type is required' }
            if (role === "") { handleError = 'Main Discipline is required' }
            if (jobLevel === "") { handleError = 'SDSIC Experience is required' }
            if (terms === false) { handleError = 'You must agree to the SDSIC privacy terms' }
            if (payAgreed === false) { handleError = 'You must agree to the SDSIC conference policies'}
            if (country === "") { handleError = 'Country is required' }
            if (stateProvince === "") { handleError = 'State or Province is required' }
            if (zipPostal === "") { handleError = 'Zip or Postal Code is required' }
        }
        if (key === 'review') {
            if (email === "") {
                handleError = 'You must have at least one user defined to select their agenda.'
            }
            if (day_1 === false & day_2 === false & day_3 === false & day_4 === false) {
                handleError = 'You must choose at least one day to attend'
            } else {
                addNewAttendeeToList()
            }
        }


        if (key === 'agenda') {
            if (email === "") {
                handleError = 'You must have at least one user defined for payment.'
            }
        };

        if (key === 'payment') {
            if (userList.length == 0) {
                handleError = 'You must have at least one user defined for payment.'
            }
        };

        if (handleError !== "") {
            setError(handleError)
        } else {
            setKey(key)
        }

    }

    const selectCountry = (val) => {
        setCountry(val);
        setError("");
    }

    const selectRegion = (val) => {
        setStateProvince(val);
        setError("");
    }

    const showError = () => {
        return (
            <div className="alert alert-danger">
                {error}
            </div>
        )
    };

    const calculateTotalCost = (Qty) => {
        var total = Qty * EventInformation.cost
        return total
    };

    const removeUserFromList = (email) => {

        setUserList(current =>
            current.filter(user => {
                return user.email !== email;
            }),
        );
        setKey('attendees');
    };

    const addNewAttendeeToList = () => {

        // check if the user is already in the list or not
        var found = userList.find((element) => {
            return element.email === email;
        })
        // if it's not found, add it
        if (!found) {
            const newUser = {
                firstName,
                lastName,
                email,
                companyName,
                country,
                stateProvince,
                workphone,
                day_1,
                day_2,
                day_3,
                day_4,
                industry,
                role,
                jobLevel,
                sponsorInterest,
                accessibility
            }
            const newList = [...userList, newUser]
            setUserList(newList)
        }
    };


    // function works with multiple attendees
    const addMultipleAttendees = () => {

        // set all fields to orignal state
        setFirstName('');
        setLastName('');
        setEmail('');
        setWorkphone('');
        setDay_1(false);
        setDay_2(false);
        setDay_3(false);
        setDay_4(false);
        setCompanyName('');
        setIndustry('');
        setRole('');
        setJobLevel('');
        setSponsorInterest(false);
        setAccessibility(false);
        setTerms(false);
        setCountry('');
        setStateProvince('');
        setZipPostal('');
        // set the form wizard back to attendee
        setKey('attendees');
    };

    const handleChange = name => event => {

        switch (name) {
            case 'firstName': {
                setFirstName(event.target.value)
                setError("");
                break;
            }
            case 'lastName': {
                setLastName(event.target.value)
                setError("");
                break;
            }
            case 'email': {
                setEmail(event.target.value)
                setError("");
                break;
            }
            case 'workphone': {
                setWorkphone(event.target.value)
                setError("");
                break;
            }
            case 'companyName': {
                setCompanyName(event.target.value)
                setError("");
                break;
            }
            case 'industry': {
                setIndustry(event.target.value)
                setError("");
                break;
            }
            case 'role': {
                setRole(event.target.value)
                setError("");
                break;
            }
            case 'jobLevel': {
                setJobLevel(event.target.value)
                setError("");
                break;
            }
            case 'terms': {
                setTerms(event.target.checked)
                setError("");
                break;
            }
            case 'payAgreed': {
                setPayAgreed(event.target.checked)
                setError("");
                break;
            }
            case 'sponsorInterest': {
                setSponsorInterest(event.target.checked)
                setError("");
                break;
            }
            case 'accessibility': {
                setAccessibility(event.target.checked);
                setError("");
                break;
            }
            case 'day_1': {
                setDay_1(event.target.checked)
                setError("");
                break;
            }
            case 'day_2': {
                setDay_2(event.target.checked)
                setError("");
                break;
            }
            case 'day_3': {
                setDay_3(event.target.checked)
                setError("");
                break;
            }
            case 'day_4': {
                setDay_4(event.target.checked)
                setError("");
                break;
            }
            case 'zipPostal': {
                setZipPostal(event.target.value)
                setError("");
                break;
            }
            default: {
                setError("");
                break;
            }
        }
    };

    return (
        <div className="container">
            <div className="text-center">

                <h2>Registration for the SDSIC 2025 Event in Chattanooga, TN</h2>
                <h2>Hosted By: </h2>
                <img className="d-block mx-auto mb-1" src={BV_LOGO} alt="" height="200" />
            <br/>
            </div>

            <Tab.Container activeKey={key} onSelect={handleSelect} >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="attendees">Attendee Information</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="agenda">Agenda Choices</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="review">Review</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="payment">Payment</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="attendees">
                                <div className="row s-box">
                                    {error && showError()}
                                    <Form >
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    placeholder="First name"
                                                    name="firstName"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("firstName")}
                                                    value={firstName}
                                                    tabIndex="1"
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Email"
                                                    name="email"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("email")}
                                                    value={email}
                                                    tabIndex="3"
                                                />
                                                <br />
                                                <CountryDropdown
                                                    className="form-control"
                                                    name="country"
                                                    placeholder="Country"
                                                    value={country}
                                                    tabIndex="5"
                                                    onChange={(val) => selectCountry(val)} 
                                                />                                                
                                                <br />
                                                <Form.Control
                                                    placeholder="Work Phone"
                                                    name="phone"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("workphone")}
                                                    value={workphone}
                                                    tabIndex="7"
                                                />
                                                <br />
                                                <Form.Control
                                                    as="select"
                                                    placeholder="Industry"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("industry")}
                                                    tabIndex="9"
                                                    value={industry}>
                                                    <option>Choose your company type</option>
                                                    <option>Engineering Firm</option>
                                                    <option>Utility Company</option>
                                                    <option>Vendor</option>
                                                </Form.Control>
                                                <br />
                                                <Form.Control
                                                    as="select"
                                                    placeholder="Core Discipline"
                                                    className="form-control"
                                                    tabIndex="11"
                                                    type="text"
                                                    onChange={handleChange("role")}
                                                    value={role}>
                                                    <option>Choose your main discipline</option>
                                                    <option>Civil Design</option>
                                                    <option>Electrical Design</option>
                                                    <option>Information Technology</option>
                                                    <option>Management</option>
                                                    <option>Physical Design</option>
                                                    <option>Technology/Innovation</option>
                                                    <option>None of these</option>
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Last name"
                                                    name="lastName"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("lastName")}
                                                    value={lastName}
                                                    tabIndex="2"
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Company"
                                                    name="company"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("companyName")}
                                                    value={companyName}
                                                    tabIndex="4"
                                                />
                                                <br />
                                                <RegionDropdown
                                                    className="form-control"
                                                    placeholder="State / Province"
                                                    country={country}
                                                    value={stateProvince}
                                                    onChange={(val) => selectRegion(val)} 
                                                    tabIndex="6"
                                                />
                                                <br />
                                                <Form.Control
                                                    placeholder="Zip/Postal Code"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChange("zipPostal")}
                                                    value={zipPostal}
                                                    tabIndex="8"
                                                />


                                                <br />
                                                <Form.Control
                                                    as="select"
                                                    placeholder="Expertise Level"
                                                    className="form-control"
                                                    type="text"
                                                    tabIndex="10"
                                                    onChange={handleChange("jobLevel")}
                                                    value={jobLevel}>
                                                    <option>Choose your SDSIC experience</option>
                                                    <option>Never Attended Before</option>
                                                    <option>Attended 1 to 3 events</option>
                                                    <option>Attend Most Events </option>
                                                    <option>Been there Since the Beginning</option>
                                                </Form.Control>

                                                <br />
                                                <Form.Group id="formGridCheckboxSpecialAccess ">
                                                    <Form.Check
                                                        type="checkbox"
                                                        tabIndex="12"
                                                        checked={accessibility}
                                                        onChange={handleChange('accessibility')}
                                                        label='Special accessibility accommodations required.'
                                                    />
                                                </Form.Group>
                                                <br />

                                            </Col>

                                        </Row>
                                        <br />
                                        <Form.Group id="formGridCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                checked={sponsorInterest}
                                                onChange={handleChange('sponsorInterest')}
                                                label='I / We are interested in sponsoring this event '
                                            />
                                        </Form.Group>
                                        <Form.Group id="formGridCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                checked={payAgreed}
                                                onChange={handleChange('payAgreed')}
                                                label='I agree to the conference policies outined in the document link below'
                                            />
                                            <span><a href="/policystatement" target="_blank">Conference Policies.</a></span>
                                        </Form.Group>
                                        <Form.Group id="formGridCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                checked={terms}
                                                onChange={handleChange('terms')}
                                                label='I agree to the use of my personal information as described in the SDSIC '
                                            />
                                            <span><a href="/privacystatement" target="_blank">Privacy Statement.</a></span>
                                        </Form.Group>
                                        
                                    </Form>
                                    <div className="row">
                                        <div className="col-md-10 order-md-1">

                                        </div>
                                        <div className="col-md-2 order-md-2 mb-4">
                                            <Button variant="success" onClick={() => handleSelect("agenda")}>Next</Button>
                                        </div>
                                    </div>


                                    <div>
                                        {(userList.length > 0) && <table>
                                            <tr>
                                                <th>Users in your list</th>
                                                <th></th>
                                            </tr>
                                            <td>
                                                {userList.map((user, i) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{user.email}</td>
                                                                <td><Button variant="danger" onClick={() => removeUserFromList(user.email)}>Remove</Button></td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </td>
                                        </table>}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="agenda">
                                <div className="row s-box">
                                    <div className="row">
                                        <br />
                                        <Form.Label for="meetingSelect">Choose your primary agenda</Form.Label>
                                        <div className="row justify-content-md-center">
                                            <div className="Col">
                                                {error && showError()}
                                                <Form >
                                                    <Row>
                                                        <Col>
                                                            <Form.Group id="day_1Checkbox">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={day_1}
                                                                    onChange={handleChange('day_1')}
                                                                    label='April 28th [afternoon/evening reception]'
                                                                />
                                                            </Form.Group>
                                                            <Form.Group id="day_2Checkbox">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={day_2}
                                                                    onChange={handleChange('day_2')}
                                                                    label='April 29th [full day]'
                                                                />
                                                            </Form.Group>
                                                            <Form.Group id="day_3Checkbox">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={day_3}
                                                                    onChange={handleChange('day_3')}
                                                                    label='April 30th [full day]'
                                                                />
                                                            </Form.Group>
                                                            <Form.Group id="day_4Checkbox">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    checked={day_4}
                                                                    onChange={handleChange('day_4')}
                                                                    label='May 1st [morning session]'
                                                                />
                                                            </Form.Group>

                                                        </Col>
                                                    </Row>
                                                </Form>
                                                <div className="row">
                                                    <div className="col-md-8 order-md-1">

                                                    </div>
                                                    <div className="col-md-2 order-md-1">
                                                        <Button variant="primary" onClick={() => handleSelect("attendees")}>Back</Button>
                                                    </div>
                                                    <div className="col-md-2 order-md-2 mb-4">
                                                        <Button variant="success" onClick={() => handleSelect("review")}>Next</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="review">
                                <div className="row s-box">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {(userList.length > 0) &&
                                                userList.map((user, i) => {
                                                    return (

                                                        <Row>
                                                            <Col sm={9}>
                                                                <p><b>{`User no. ${i + 1}`}</b></p>

                                                                <p><i>Please review your entries carefully.</i></p>
                                                                <p>First Name:   <b>{user.firstName}</b></p>
                                                                <p>Last Name:   <b>{user.lastName}</b></p>
                                                                <p>Email:  <b>{user.email}</b></p>
                                                                <p>Company Name:    <b>{user.companyName}</b></p>
                                                                <p>Country: <b>{user.country}</b></p>
                                                                <p>Region:  <b>{user.stateProvince}</b></p>
                                                                <p>Work Phone:  <b>{user.workphone}</b></p>
                                                                <p>Days attending: <b>{user.day_1 && 'April 28th'} {user.day_2 && 'April 29th'} {user.day_3 && 'April 30th'} {user.day_4 && 'May 1st'}</b></p>
                                                                <p>Company Type:    <b>{industry}</b></p>
                                                                <p>Discipline:  <b>{role}</b></p>
                                                                <p>SDSIC Experience:    <b>{jobLevel}</b></p>
                                                                <p>Special accessibility accommodations required: <b>{accessibility && 'Yes'}{!accessibility && 'No'}</b></p>
                                                                <p>Interested in Sponsoring:    <b>{sponsorInterest && 'Yes'}{!sponsorInterest && 'No'}</b></p>

                                                            </Col>
                                                            <Col sm={3}>
                                                                <p><Button variant="danger" onClick={() => removeUserFromList(user.email)}>Remove</Button></p>
                                                            </Col>
                                                            <hr />
                                                        </Row>
                                                    )
                                                })
                                            }


                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 order-md-1">
                                            <Button variant="primary" onClick={() => handleSelect("agenda")}>Back</Button>
                                        </div>
                                        <div className="col-md-3 order-md-1">
                                            <Button variant="info" onClick={() => addMultipleAttendees()}>Add More Attendees</Button>
                                        </div>
                                        <div className="col-md-3 order-md-2 mb-4">
                                            <Button variant="success" onClick={() => handleSelect("payment")}>Proceed to Payment</Button>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane id="4" eventKey="payment">
                                <div className="row s-box">
                                    {paymentCompleted ? successMessage(receiptUrl, receiptEmail, receiptName) : <React.Fragment>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            {cart(userList)}
                                        </div>
                                        <div className="col-md-7 order-md-1">
                                            <Elements stripe={stripePromise}>
                                                <CheckoutForm
                                                    eventId={eventId}
                                                    amount={calculateTotalCost(userList.length)}
                                                    setPaymentCompleted={setPaymentCompleted}
                                                    setReceiptUrl={setReceiptUrl}
                                                    setReceiptEmail={setReceiptEmail}
                                                    setReceiptName={setReceiptName}
                                                    userList={userList}
                                                />
                                            </Elements>
                                        </div>
                                        <div className="col-md-5 order-md-2 mb-4">
                                            <br />
                                            <Button variant="success" onClick={() => handleSelect("review")}>Back</Button>
                                        </div>
                                    </React.Fragment>}


                                </div>


                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <br />
            <br />
            <br />
            <br />
        </div >
    );
}

export default Registration25;
